<script setup lang="ts">
const { t } = useI18n()

const items = ref([
  {
    title: t('general.nav_banner.zero_waste_packaging'),
    subtitle: t('general.nav_banner.zero_waste_packaging_info'),
    icon: 'mingcute:leaf-line',
  },
  {
    title: t('general.nav_banner.return_policy_title'),
    subtitle: t('general.nav_banner.return_policy_info'),
    icon: 'mingcute:back-2-line',
  },
])

const remHeight = 4.5
const translateY = ref(0)
const transitionEnabled = ref(true)

function updateSlide() {
  if (translateY.value === -(remHeight * (items.value.length - 1))) {
    transitionEnabled.value = false
    translateY.value = 0

    nextTick(() => {
      transitionEnabled.value = true
    })
  }
  else {
    translateY.value -= remHeight
  }
}

let intervalId: NodeJS.Timeout | null = null
const intervalTime = 3000

onMounted(() => {
  intervalId = setInterval(updateSlide, intervalTime)
})

onUnmounted(() => {
  intervalId && clearInterval(intervalId)
})
</script>

<template>
  <div class="overflow-hidden">
    <div class="relative z99 mx-auto my0 overflow-hidden rd-xl shadow">
      <div class="w-full flex flex-row items-center justify-center">
        <!-- Left side -->
        <div
          class="relative h18 w-1/2 flex flex-col items-start justify-center bg-gray-100 px2"
          tabindex="-1"
        >
          <div class="w-full flex flex-row items-center justify-start" aria-hidden="true">
            <NIcon icon="i-i-mingcute:truck-line" n="lg" />
            <div class="ml1 whitespace-nowrap py1 text-slate-900 font-bold">
              <div class="m:text-sm xs:text-xs">
                {{ t('general.nav_banner.sustainable_delivery') }}
              </div>
            </div>
          </div>
          <div class="max-w-full of-hidden">
            <div class="max-w-full flex flex-row items-center justify-start" aria-hidden="true">
              <div class="text-xs text-slate-800 sm:text-sm">
                <div>
                  {{ t('general.nav_banner.sustainable_delivery_now_free') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side -->
        <ul
          class="h18 w-1/2"
          :style="{ transform: `translateY(${translateY}rem)`, transition: transitionEnabled ? 'transform 500ms' : 'none' }"
        >
          <div
            v-for="(item, index) in items"
            :key="index"
            class="relative h18 flex flex-col items-start justify-center bg-gray-100 px4 !w-full"
            :aria-label="item.title + item.subtitle"
            tabindex="-1"
          >
            <div class="w-full flex flex-row items-center justify-start" aria-hidden="true">
              <NIcon :icon="item.icon" n="lg" />
              <div class="ml1 whitespace-nowrap py1 text-slate-900 font-bold">
                <div class="m:text-sm xs:text-xs">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div class="max-w-full of-hidden">
              <div class="max-w-full flex flex-row items-center justify-start" aria-hidden="true">
                <div class="text-xs text-slate-800 sm:text-sm">
                  <div>
                    {{ item.subtitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div
        aria-hidden="true"
        class="absolute right-1/2 top-5 z-1 h8 w1 scale-x-[.5] op-[.6]"
        style="background-image:linear-gradient(rgba(255,255,255,0) 0%, #000000 25%, #000000 75%, rgba(255,255,255,0) 100%)"
      />
    </div>
  </div>
</template>
